<template>
  <FormModal class="form-modal--verify-phone">
    <template #body>
      <NavigationBar />

      <PhoneVerifyFormStep
        ref="phoneVerify"
        :next-step="nextStep"
        :twilio-service-sid="twilioServiceSid"
        :phone-number="phoneNumber"
        :progress-value="progressValue"
      />
    </template>

    <template #footer>
      <div />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import NavigationBar from 'chimera/all/themes/blueflow/components/layout/NavigationBar'
import PhoneVerifyFormStep from 'chimera/all/components/form/steps/phoneVerify/PhoneVerifyFormStep.vue'
import ThankYouPage from '~/pages/richieste-di-preventivi/preventivo-completato'

export default {
  components: {
    NavigationBar,
    PhoneVerifyFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Controlla il tuo telefono',
      headDescription:
        'Inserisci i tuoi dati per metterci in contatto con lo specialista più adatto nella tua zona.',
      path: '/richieste-di-preventivi/controlla-il-tuo-telefono',
      progressValue: 95,
      twilioServiceSid: process.env.twilioServiceSid,
      nextStep: ThankYouPage,
    }
  },

  computed: {
    /**
     * @returns {*}
     */
    phoneNumber() {
      return this.$store.getters['lead/getData']('phone', '')
    },
  },

  /**
   */
  beforeMount() {
    this.setCheckoutStep(6)
  },
}
</script>
